import { DataGrid } from '@mui/x-data-grid';
import { useGetCustomers } from '../hooks/Customers';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

const columns = [
    { field: 'id', headerName: '' },
    { field: 'nombreCliente', headerName: 'Nombre Cliente', width: 400 },
    { field: 'actions', headerName: 'Acciones', width: 400, renderCell: () => (
        <Stack direction="row" spacing={1}>
        <Button
          size="small"
          variant="contained"
        >Editar
        </Button>
        <Button
          size="small"
          variant="contained"
        >Borrar
        </Button>
      </Stack>
    )},
]

const Customers = ({ accessToken }) => {
    const [tableData] = useGetCustomers(accessToken);
    console.log("Table Data: ", tableData);

    return (
        <div style={{ height: 700, width: '80%', alignItems: 'center', justifyContent: 'center' }}>
            { tableData != null && <DataGrid
                getRowId={(row) => row.nombreCliente}
                rows={tableData}
                columns={columns}
                pageSize={12}
            />}
        </div>
    )
};

export default Customers;