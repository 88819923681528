import { useState, useEffect } from "react";
import { Params } from "../params";

export const useGetCustomers = (bearerToken) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    console.log("Getting Customers")
    let getCustomersPayload = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${bearerToken}`
      },
    }
    fetch(Params.apiEndpoint + "/clientes/", getCustomersPayload)
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((err) => {
        console.log(err.message);
      });
  }, [bearerToken]);

  return [data];
};
