import { Outlet, Link } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

const Layout = () => {

  return (
    <>
      <AppBar position="static">
        <Stack direction="row" spacing={2}>
          <Button
            size="large"
            color="inherit"
            component={Link}
            to={'/'}
          >Home
          </Button>
          <Button
            size="large"
            color="inherit"
            component={Link}
            to={'/customers'}
          >Customers
          </Button>
        </Stack>
      </AppBar>
      <Outlet />
    </>
  )
};

export default Layout;


