import './App.css';
import { useSearchParams, Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import { Params } from './params';
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Customers from './pages/Customers';
import NoPage from "./pages/NoPage";

const App = () => {
  const [accessToken, setAccessToken] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  console.log("Params: ", Params);

  useEffect(() => {
    if (accessToken == null) {
      const authCode = searchParams.get('code');
      if (authCode == null) {
        window.location.replace(Params.cognitoUrl +
          "/authorize?identity_provider=Google&client_id=" +
          Params.cognitoUserPoolClient +
          "&response_type=code&scope=email+openid+profile" +
          "&redirect_uri=" +
          Params.cognitoCallbackUrl);
      } else {
        var urlencoded = new URLSearchParams();
        urlencoded.append("grant_type", "authorization_code");
        urlencoded.append("code", authCode);
        urlencoded.append("client_id", Params.cognitoUserPoolClient);
        urlencoded.append("redirect_uri", Params.cognitoCallbackUrl);

        fetch(Params.cognitoUrl + '/token', {
          method: 'POST',
          body: urlencoded,
          headers: { 'Content-type': 'application/x-www-form-urlencoded' }
        })
          .then((response) => response.json())
          .then((data) => {
            // console.log("JwT: ",data);
            setAccessToken(data.access_token);
            searchParams.delete('code');
            setSearchParams({});
          })
          .catch((err) => {
            console.log(err.message);
          });
      }
    } else {
      let JwTData = JSON.parse(atob(accessToken.split(".")[1]));
      console.log("JwT Data: ", JwTData);
    }
  }, [searchParams, setSearchParams, accessToken]);


  return (
      <Routes>
        <Route path="/" element={
          <Layout />
        }>
          <Route index element={<Home />} />
          <Route path="customers" element={
            <Customers
            accessToken={accessToken}
            />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
  );
};

export default App;